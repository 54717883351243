<template>
    <div>
        <CheckoutHeader :back-button-action="backBtnAction" class="-mb-4">
            <template v-slot:back>
                {{ $t("checkout.back_checkout_edit") }}
            </template>
            <template v-slot:header>
                <template>
                    <span data-cy="choose-wizard_control-text">
                        2 {{ $t("checkout.of") }} 2 &mdash;
                    </span>
                </template>
                <strong data-cy="choose-wizard_control_choose-text">
                    {{ $t("checkout.nf_emit") }}</strong
                >
            </template>
            <template v-slot:sub-header>
                {{ $t("plan") }}
                <strong data-cy="payment_method-plan-text"
                    >
                    {{ $t("recurrences_num")[getChosenPlan?.recurrencePeriod] }}
                </strong>
                &mdash;
                <span data-cy="choose-plan_price-text" v-html="planPrice" />
            </template>
        </CheckoutHeader>

        <form class="form mt-8" @submit.prevent="save" >
            <FormulateForm class="text-left">
                <div >
                    <div>
                        <FormulateInput
                            v-model="getProfile.person.owner"
                            type="text"
                            name="company_name"
                            :label="$t('form.company_owner')"
                            validation="required"
                            :validation-messages="{
                                required: $t('form.required_field'),
                            }"
                            :error-class="['text-red-600']"
                            icon=""
                            :outer-class="['w-full']"
                            data-cy="profile_form-company_name-input"
                            @click="eventGtm('custom.editCompanyName')"
                        />
                    </div>
                        <div class="flex flex-wrap">
                        <FormulateInput
                        type="select"
                        name="documentType"
                        :label="$t('form.document_type')"
                        v-model="profile.person.doc_type"
                        :options="tipoDocList"
                        :outer-class="['w-full sm:w-1/3 sm:pl-1 md:w-1/3']"
                        :error-class="['text-red-600']"
                        data-cy="profile_form-document-type"
                        @click="eventGtm('custom.editDocumentType')"
                        />
                        <FormulateInput
                            v-mask="mask"
                            v-model="profile.person.cpf_cnpj"
                            type="text"
                            :label="$t('form.document_number')"
                            name="document"
                            icon="id-card"
                            inputmode="text"
                            validation="bail|required|valid"
                            :validation-rules="{
                                valid:  () => validateDocument
                            }"
                            :validation-messages="{
                                required: $t('form.required_field'),
                                valid:  $t('form.invalid'),
                            }"
                            :error-class="['text-red-600']"
                            :outer-class="['w-full sm:w-2/3 sm:pr-1 md:w-2/3 md:pl-1']"
                            data-cy="profile_form-document-number"
                            @click="eventGtm('custom.editDocument')"
                        />
                    </div>
                </div>
            </FormulateForm>
            <form-address
                @addressData="updateBillingAddress"
                @setError="setError"
                address-type="billingAddress"
            />

            <div class="mt-6">
                <submit-button-default
                    :text="$t('advance')"
                    data-cy="checkout_edit-submit-btn"
                    :loading="loading"
                    :disabled="!stepValid_2 || !stepValid_1 || this.error"
                    @click.native="eventGtm('custom.nextUpdateBillingAddress')"
                />
            </div>
        </form>
    </div>
</template>

<script>
import {
    CHECKOUT_PROFILE_EDIT_VIEW,
    PAYMENT_METHOD_SELECTOR_VIEW,
    PLAN_SELECTOR_VIEW,
} from "@/router/constants";
import {
    CHECKOUT_MODULE,
    GET_RECURRENCE,
    GET_PLAN,
    CHANGE_PAYMENT_METHOD_BANKSLIP,
    GET_CHOSEN_PLAN,
} from "../store/constants/checkout";
import { Currency } from "@/constants";
import { mapGetters, mapActions } from "vuex";
import CheckoutHeader from "@/components/Checkout/Header.vue";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import { mask } from "vue-the-mask";
import {cuilValidator } from "@/helpers/validateDocuments";

import {
    GET_STORE,
    STORE,
    STORE_MODULE,
    UPDATE_PROFILE,
} from "@/store/constants/store";
import FormAddress from "@/components/Form/FormAddress.vue";

export default {
    name: CHECKOUT_PROFILE_EDIT_VIEW,
    components: {
        CheckoutHeader,
        SubmitButtonDefault,
        FormAddress,
    },
    directives: { mask },
    data() {
        return {
            profile: {
                person: {},
                address: {},
                document: "",
                documentType: ""
            },
            routeEdit: PLAN_SELECTOR_VIEW,
            loading: false,
            step: 1,
            error: true,
            address: "",
            documents: "",
            tipoDoc:"",
            tipoDocList: {
                CUIT:"CUIT",
                CUIL:"CUIL",
                CDI:"CDI",
                DNI:"DNI",
            },
        };
    },
    computed: {
        ...mapGetters({
            getRecurrence: `${CHECKOUT_MODULE}/${GET_RECURRENCE}`,
            getPlan: `${CHECKOUT_MODULE}/${GET_PLAN}`,
            getChosenPlan: `${CHECKOUT_MODULE}/${GET_CHOSEN_PLAN}`,
            getProfile: `${STORE_MODULE}/${STORE}`,
            eventGtm: 'eventGtm'
        }),
        mask(){
            if (this.profile.person.doc_type == 'DNI' ) return ['##.###.###']  
                else return ['##-########-#']           
        },
        validateDocumentDigits(){

            if (this.profile.person.doc_type == 'DNI' && this.profile.person.cpf_cnpj.length == 10) return true
            else if (this.profile.person.doc_type && this.profile.person.doc_type != 'DNI' && this.profile.person.cpf_cnpj.length == 13) return true
            else return false
        },
        validateDocument(){
            if(this.profile.person.doc_type == 'CUIL' || this.profile.person.doc_type == 'CUIT'){
                return cuilValidator(this.profile.person.cpf_cnpj)
            }
            return true
        },
        stepValid_1() {
            return  this.profile.person.doc_type && this.getProfile.person.owner && this.profile.person.cpf_cnpj && this.validateDocumentDigits  && this.validateDocument;

        },
        stepValid_2() {
            return this.profile.address.city && this.profile.address.country  && this.profile.address.state && this.profile.address.street && this.profile.address.zipcode 

        },
        planPrice() {
            return `${Currency[this.getChosenPlan?.corrency]}  ${
                this.getChosenPlan?.price
            }<small>,00
        <em>${
            this.$t("recurrences_freq_num")[
                this.getChosenPlan?.recurrencePeriod
            ]
        }</em>
        </small>`;
        },
    },
    async mounted() {
        this.profile.person = this.getProfile.person;
        this.profile.address = this.getProfile.billingAddress;
        this.profile.document = this.getProfile.document;
        this.profile.documentType = this.getProfile.documentType;
    },
    methods: {
        ...mapActions({
            changePaymentMethod: `${CHECKOUT_MODULE}/${CHANGE_PAYMENT_METHOD_BANKSLIP}`,
            updateStoreAction: `${STORE_MODULE}/${UPDATE_PROFILE}`,
            requestStoreAction: `${STORE_MODULE}/${GET_STORE}`,
        }),

        backBtnAction() {
            return this.$router.push({
                name: PLAN_SELECTOR_VIEW,
            });
        },
        async save() {
            const person = { person: this.profile.person };
            await this.updateStoreAction(person);
            await this.requestStoreAction();
            this.step = 1;
            this.address = {
                billingAddress: this.profile.address,
                storeAddress: this.profile.address,
            };
            await this.updateStoreAction(this.address);
            this.documents = {
                document: this.profile.person.cpf_cnpj.replace(/[\D]/gi, ''),
                documentType: this.profile.person.doc_type,
            };
            await this.updateStoreAction(this.documents);
            await this.requestStoreAction();
            return this.$router.push({
                name: PAYMENT_METHOD_SELECTOR_VIEW,
            });
        },
        updateBillingAddress(data) {
            this.profile.address = data;
        },
        setError(data) {
            this.error = data;
        },
    },
};
</script>

<style lang="scss" scoped>
.submit-label {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.form {
    width: 80%;
    min-width: 300px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.cep-input {
    width: 46%;
    margin-right: 100%;
}
</style>
