<template>
  <div>
    <CheckoutHeader :back-button-action="isDesktop ? false : backBtnAction" class="-mb-4">
      <template v-if="!isDesktop" v-slot:back>
        {{ $t("checkout.back_init") }}
      </template>
      <template v-slot:header>
        <strong data-cy="choose-wizard_control_choose-text">
          {{ $t("checkout.change_payment_method") }}</strong
        >
      </template>
    </CheckoutHeader>
    <div class="grid grid-cols-1 gap-3 sm:w-2/3 md:w-1/2 lg:w-1/3 m-auto mb-2">
      <submit-button-default
        :text="$t('creditCard')"
        class="col-span-1 double"
        :showIcon="true"
        :fatButton="true"
        data-cy="change_payment-creditcard-btn"
        @click.native="chooseCreditCard(); eventGtm('custom.changePayment-CreditCard')"
      />
<!-- 
      <submit-button-default
        v-if="getCurrentPaymentMethod === 'credit_card'|| getCurrentPaymentMethod === 'RapiPago'"
        :text="$t('PagoFacil')"
        class="col-span-1 double"
        :showIcon="true"
        :fatButton="true"
        data-cy="change_payment-bankslip-btn"
        @click.native="choosePagoFacil(); eventGtm('custom.changePayment-bankslip')"
      />
      <submit-button-default
        v-if="getCurrentPaymentMethod === 'credit_card' || getCurrentPaymentMethod === 'PagoFacil'"
        :text="$t('RapiPago')"
        class="col-span-1 double"
        :showIcon="true"
        :fatButton="true"
        data-cy="change_payment-bankslip-btn"
        @click.native="chooseRapiPago(); eventGtm('custom.changePayment-bankslip')"
      />
-->
    </div>
  </div>
</template>

<script>
import CheckoutHeader from "@/components/Checkout/Header.vue";
import {
  CHANGE_TO_BANKSLIP,
  CHANGE_TO_CREDIT_CARD,
  HOME_VIEW,
  PLAN_SELECTOR_VIEW
} from "@/router/constants";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import { mapGetters } from "vuex";
import { STORE_MODULE } from "@/store/constants/store";
export default {
  components: {
    CheckoutHeader,
    SubmitButtonDefault,
  },
  computed: {
    ...mapGetters(STORE_MODULE, ["subscription", "paymentStatusUser"]),
    ...mapGetters({ isDesktop: `isDesktop`, eventGtm: 'eventGtm'}),
    getCurrentPaymentMethod() {
      return this.subscription.payment_type_code;
    },
  },
  methods: {
    backBtnAction() {
      return this.$router.push({
        name: HOME_VIEW,
      });
    },
    chooseCreditCard() {
      if(this.paymentStatusUser?.includes?.("paying")){
        return this.$router.push({
            name: CHANGE_TO_CREDIT_CARD,
          });
      }
      return this.$router.push({
        name: PLAN_SELECTOR_VIEW,
      });
    },
    choosePagoFacil() {
      return this.$router.push({
        name: CHANGE_TO_BANKSLIP,
        params: { method: 'PagoFacil' }
      });
    },
    chooseRapiPago() {
      return this.$router.push({
        name: CHANGE_TO_BANKSLIP,
        params: { method: 'RapiPago' }
      });
    },
  },
};
</script>

<style>
</style>