var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CheckoutHeader", {
        staticClass: "-mb-4",
        attrs: {
          "back-button-action": _vm.isDesktop ? false : _vm.backBtnAction,
        },
        scopedSlots: _vm._u(
          [
            !_vm.isDesktop
              ? {
                  key: "back",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("checkout.back_init")) + " "),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "strong",
                    {
                      attrs: { "data-cy": "choose-wizard_control_choose-text" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "checkout.changePayment.paymentSlip.changeToPaymentSlip"
                            )
                          )
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      !_vm.isConfirmed
        ? _c("div", { staticClass: "w-full text-center" }, [
            _c(
              "div",
              {
                staticClass:
                  "border border-gray-600 inline-block p-5 text-left w-2/3",
                attrs: { "data-cy": "checkout-banner_success_bankslip-btn" },
              },
              [
                _c("p", { staticClass: "font-semibold" }, [_vm._v("Atenção")]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "checkout.changePayment.paymentSlip.shippingInformation"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "checkout.changePayment.paymentSlip.confirmPaymentInfo"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "mt-10" },
              [
                _c("submit-button-default", {
                  attrs: {
                    "show-icon": false,
                    fatButton: true,
                    text: "Aceitar",
                    "data-cy": "checkout-bankslip_confirm-btn",
                  },
                  nativeOn: {
                    click: function ($event) {
                      _vm.confirmChange()
                      _vm.eventGtm("custom.changePayment-ConfirmBankSlip")
                    },
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.isConfirmed
        ? _c("div", [
            _vm.newPaymentInfo.success
              ? _c("div", { staticClass: "text-center w-full" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "border border-nexteal-500 bg-nexteal-100 p-5 text-nexteal-800 w-2/3 inline-block text-left",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "font-semibold",
                          attrs: {
                            "data-cy": "checkout-success_bankslip-text",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "checkout.changePayment.paymentSlip.changeSuccessFull"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        { attrs: { "data-cy": "checkout-bankslip_info-text" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "checkout.changePayment.paymentSlip.nextPaymentInfo"
                                )
                              ) +
                              " "
                          ),
                          _c("b", [_vm._v(_vm._s(_vm.getEmail))]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "banner-1 w-2/3 inline-block",
                      attrs: { "data-cy": "checkout-bankslip_info_email-text" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "checkout.changePayment.paymentSlip.infoNextPaymentSlip"
                            )
                          ) +
                          " " +
                          _vm._s(_vm.nextBillingDate) +
                          " "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "w-full text-center mt-10" },
              [
                _c("submit-button-default", {
                  attrs: {
                    text: _vm.$t("understood"),
                    fatButton: true,
                    "data-cy": "checkout-success_bankslip-btn",
                  },
                  nativeOn: {
                    click: function ($event) {
                      _vm.agreeAction()
                      _vm.eventGtm("custom.changePayment-BlankSlip-Success")
                    },
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }