import { invalidCpf, invalidCnpj } from './invalidDocuments'

const is = (value, constructor) => {
    return Object.prototype.toString.call(value) === `[object ${constructor}]`
}

export const cuilValidator = (cuil) => {
  let cuit = cuil.replace(/[^\d]+/g,'')

    let acumulado = 0;
    let digitos = cuit.split('');
    let digito = parseInt(digitos.pop());
  
    for (let i = 0; i < digitos.length; i++) {
      acumulado += digitos[9 - i] * (2 + (i % 6));
    }
    
  
    let verif = 11 - (acumulado % 11);
    if (verif === 11) {
      verif = 0;
    } else if (verif === 10) {
      verif = 9;
    }
    return digito === verif;
  }

export const isCPF = (cpf) => {
    const isInvalid = (cpf, rest, pos) => rest !== parseInt(cpf.substring(pos, pos + 1))

    const sumDigit = (cpf, digit) => 11 - (cpf.substring(0, digit).split('').reduce((acc, curr, index) => {
        acc += parseInt(curr) * ((digit + 1) - index)
        return acc
    }, 0) % 11)

    const getRest = sum => sum > 9 ? 0 : sum

    if (!is(cpf, 'String')) return false

    cpf = cpf.replace(/[\D]/gi, '')

    const invalid = invalidCpf.find((number) => cpf == number);
    if( invalid ) return false

    if (!cpf.match(/^\d+$/)) return false

    if (cpf.length !== 11) return false

    if (isInvalid(cpf, getRest(sumDigit(cpf, 9)), 9)) return false

    if (isInvalid(cpf, getRest(sumDigit(cpf, 10)), 10)) return false

    return true
}

export const isCNPJ = (value) => {
    if (!is(value, 'String')) {
        return false
    }

    const digits = value.replace(/[\D]/gi, '')
    if (digits.length !== 14) return false

    const invalid = invalidCnpj.find((number) => digits == number);
    if( invalid ) return false

    let dig1 = 0
    let dig2 = 0

    const validation = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

    const digito = parseInt(digits.charAt(12) + digits.charAt(13))

    const getRest = dig => (((dig % 11) < 2) ? 0 : (11 - (dig % 11)))

    validation.map((v, i) => {
        dig1 += (i > 0 ? (digits.charAt(i - 1) * v) : 0)
        dig2 += digits.charAt(i) * v
    })

    dig1 = getRest(dig1)
    dig2 = getRest(dig2)

    return (((dig1 * 10) + dig2) === digito)
}

export const isEmail = (value) => {
    if (!is(value, 'String')) {
        return false
    }
    return value.includes('@')
}

export const isCEP = (value) => {

    return /([0-9]){8}/.test(
        value
    );
}
