export const invalidCpf = [
    "00000000000",
    "11111111111",
    "22222222222",
    "33333333333",
    "44444444444",
    "55555555555",
    "66666666666",
    "77777777777",
    "88888888888",
    "99999999999",
]
export const invalidCnpj = [
    "00000000000000",
    "11111111111180",
    "22222222222259",
    "33333333333328",
    "44444444444405",
    "55555555555576",
    "66666666666645",
    "77777777777714",
    "88888888888893",
    "99999999999962",
]