<template>
    <div>
        <CheckoutHeader :back-button-action="isDesktop ? false : backBtnAction" class="-mb-4">
            <template v-if="!isDesktop" v-slot:back>
                {{ $t("checkout.back_init") }}
            </template>
            <template v-slot:header>
                <strong data-cy="choose-wizard_control_choose-text">
                    {{
                        $t(
                            "checkout.changePayment.creditCard.changeToCreditCard"
                        )
                    }}</strong
                >
            </template>
        </CheckoutHeader>
        <div v-if="!isComplete">
            <div class="my-5 w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 m-auto">
                <label>{{ $t('form.card_number') }}</label>
                <div id="card-number-element" class="border"></div>
                <label for="name">{{ $t('form.validity') }}</label>
                <div id="card-expiry-element" class="border"></div>
                <label for="name">{{ $t('form.security_code') }}</label>
                <div id="card-cvc-element" class="border"></div>
        
                <submit-button-default
                    style="width: 100%; max-width: 100%;"
                    :text="$t('form.pay_and_checkout')"
                    :fatButton="true"
                    :showIcon="true"
                    data-cy="checkout-confirm-btn"
                    @click.native="next()"
                />
            </div>
        </div>
        <div v-else class="text-center w-full">
            <div
                class="w-2/3 border border-nexteal-600 bg-nexteal-100 text-nexteal-800 p-5 text-left inline-block"
            >
                <p
                    class="font-semibold"
                    data-cy="checkout-success_creditcard-text"
                >
                    {{
                        $t(
                            "checkout.changePayment.creditCard.changeSuccessFull"
                        )
                    }}
                </p>
                <p>
                    {{
                        $t("checkout.changePayment.creditCard.nextPaymentInfo")
                    }}
                </p>
            </div>

            <div
                v-if="newPaymentInfo.error === true"
                class="w-2/3 border border-nexred-600 bg-nexred-100 text-nexred-800 p-5 text-left inline-block"
            >
                <p class="font-semibold">
                    {{
                        $t("checkout.changePayment.creditCard.creditCardError")
                    }}
                </p>

                <p class="font-semibold mt-2">
                    {{ $t("checkout.changePayment.creditCard.suport") }}
                </p>
            </div>

            <div class="w-full text-center mt-10">
                <submit-button-default
                    :text="$t('understood')"
                    :showIcon="false"
                    :fatButton="true"
                    @click.native="agreeAction(); eventGtm('custom.changePayment-CreditCard-Success')"
                    data-cy="checkout-success-btn"
                />
            </div>
        </div>
    </div>
</template>
<script src="https://js.stripe.com/v3/"></script>
<script>
import CheckoutHeader from "@/components/Checkout/Header.vue";
import { CHANGE_PAYMENT_METHOD_VIEW, HOME_VIEW } from "@/router/constants";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import { mapActions, mapGetters } from "vuex";
import {
    CHANGE_PAYMENT_METHOD_CREDIT_CARD,
    CHECKOUT_MODULE,
} from "@/store/constants/checkout";
import { STORE_MODULE, STORE } from "@/store/constants/store";
import Validation from "../plugins/Validation";
export default {
    components: {
        CheckoutHeader,
        SubmitButtonDefault,
    },
    data() {
        return {
            isComplete: false,
            cardElement:null,
            stripe: null,
            newPaymentInfo: {},
            creditCardData: {
                name: null,
                cardNumber: null,
                expiration: null,
                security: null,
                company: null,
            },
        };
    },
    computed: {
        ...mapGetters(STORE_MODULE, ["storeProfile"]),
        ...mapGetters({ 
            isDesktop: `isDesktop`, 
            eventGtm: 'eventGtm', 
            getProfile: `${STORE_MODULE}/${STORE}`
        }),
        isFilled() {
            for (const key in this.creditCardData) {
                if ([null, ""].includes(this.creditCardData[key])) {
                    return false;
                }
            }
            return true;
        },
        isValidCreditCard() {
            return Validation.validateCreditCard(this.creditCardData);
        },
    },
    mounted() {
    const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    this.stripe = stripe
    var style = {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '35px',
        fontSize: '15px',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    };
    const elements = stripe.elements()
    var cardNumberElement = elements.create('cardNumber', {style: style});
    cardNumberElement.mount('#card-number-element');

    var cardExpiryElement = elements.create('cardExpiry', {style: style});
    cardExpiryElement.mount('#card-expiry-element');

    var cardCvcElement = elements.create('cardCvc', {style: style});
    cardCvcElement.mount('#card-cvc-element');

    this.cardElement = cardNumberElement
  },
    methods: {
        ...mapActions({
            changePaymentMethod: `${CHECKOUT_MODULE}/${CHANGE_PAYMENT_METHOD_CREDIT_CARD}`,
        }),
        agreeAction() {
            if (this.isDesktop) {
                return alert("@@finalizarfluxoassinatura");
            }
            return this.$router.push({
                name: HOME_VIEW,
            });
        },
        backBtnAction() {
            return this.$router.push({
                name: CHANGE_PAYMENT_METHOD_VIEW,
            });
        },
        onChangeCreditCardData(values) {
            for (const key in values) {
                this.creditCardData[key] = values[key];
            }
        },
        onChangeCreditCardCompany(value) {
            this.creditCardData["company"] = value;
        },
        parseCreditCardData() {
           
            return {
                holder_name: name,
                card_expiration: this.creditCardData.expiration,
                card_number: this.creditCardData.cardNumber,
                card_cvv: this.creditCardData.security,
                payment_method_code: "credit_card",
                payment_company_code: this.creditCardData.company,
            };
        },
        parseCustomerData() {
            return {
                owner: this.getProfile?.person.owner,
                companyName:  this.storeProfile?.company_name,
                cpfCnpj: this.getProfile?.person.cpf_cnpj, 
                email:  this.storeProfile?.account
            };
        },
        async next() {

            const creditCard = this.parseCreditCardData();
            const customerData = this.parseCustomerData();
            const lastDigits = creditCard.card_number;
            const data = {
                creditCard: creditCard,
                gateway: "Stripe",
                customerId: this.storeProfile?.customerId,
                lastDigits: lastDigits,
                holder: customerData.owner.normalize("NFD").replace(/[^a-zA-Z\s]/g, ""),
                brand: creditCard.payment_company_code,
                shopcode: this.storeProfile?.shopCode,
                customerData: customerData,
                priceId: this.getProfile.store.priceId ?? this.getProfile.planServiceId
            };
            const tokenizedData = await this.stripe.createToken(this.cardElement)
            data.token = tokenizedData.token.id;
            this.changePaymentMethod(data).then((res) => {
                if (res.error === true && res.errorCode === "token") {
                    this.isComplete = false;
                    return;
                }

                this.isComplete = true;
                this.newPaymentInfo = res;
            });
            return true;
        },
    },
};
</script>

<style>
.border{
  border: 1px solid #E0E6EB !important;
  border-radius: 0.25rem !important;
  margin: 0 0 25px 0 !important;
  padding: 5px 15px;
}
label {
  font-size: 14px;
  color: #818181;
  text-align: left !important;
  position: relative;
  width: 100%;
  margin: 0;
  display: block;
}
</style>
