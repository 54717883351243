var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CheckoutHeader", {
        staticClass: "-mb-4",
        attrs: {
          "back-button-action": _vm.isDesktop ? false : _vm.backBtnAction,
        },
        scopedSlots: _vm._u(
          [
            !_vm.isDesktop
              ? {
                  key: "back",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("checkout.back_init")) + " "),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "strong",
                    {
                      attrs: { "data-cy": "choose-wizard_control_choose-text" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("checkout.change_payment_method"))
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-1 gap-3 sm:w-2/3 md:w-1/2 lg:w-1/3 m-auto mb-2",
        },
        [
          _c("submit-button-default", {
            staticClass: "col-span-1 double",
            attrs: {
              text: _vm.$t("creditCard"),
              showIcon: true,
              fatButton: true,
              "data-cy": "change_payment-creditcard-btn",
            },
            nativeOn: {
              click: function ($event) {
                _vm.chooseCreditCard()
                _vm.eventGtm("custom.changePayment-CreditCard")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }