<template>
  <div>
    <CheckoutHeader :back-button-action="isDesktop ? false : backBtnAction" class="-mb-4">
      <template v-if="!isDesktop" v-slot:back>
        {{ $t("checkout.back_init") }}
      </template>
      <template v-slot:header>
        <strong data-cy="choose-wizard_control_choose-text">
          {{
            $t("checkout.changePayment.paymentSlip.changeToPaymentSlip")
          }}</strong
        >
      </template>
    </CheckoutHeader>
    <div v-if="!isConfirmed" class="w-full text-center">
      <div
        class="border border-gray-600 inline-block p-5 text-left w-2/3"
        data-cy="checkout-banner_success_bankslip-btn"
      >
        <p class="font-semibold">Atenção</p>
        <p>
          {{ $t("checkout.changePayment.paymentSlip.shippingInformation") }}
        </p>
        <p>
          {{ $t("checkout.changePayment.paymentSlip.confirmPaymentInfo") }}
        </p>
      </div>
      <div class="mt-10">
        <submit-button-default
          :show-icon="false"
          :fatButton="true"
          text="Aceitar"
          data-cy="checkout-bankslip_confirm-btn"
          @click.native="confirmChange(); eventGtm('custom.changePayment-ConfirmBankSlip')"
        />
      </div>
    </div>
    <div v-if="isConfirmed">
      <div v-if="newPaymentInfo.success" class="text-center w-full">
        <div
          class="
            border border-nexteal-500
            bg-nexteal-100
            p-5
            text-nexteal-800
            w-2/3
            inline-block
            text-left
          "
        >
          <p class="font-semibold" data-cy="checkout-success_bankslip-text">
            {{ $t("checkout.changePayment.paymentSlip.changeSuccessFull") }}
          </p>
          <p data-cy="checkout-bankslip_info-text">
            {{ $t("checkout.changePayment.paymentSlip.nextPaymentInfo") }}
            <b>{{ getEmail }}</b>
          </p>
        </div>

        <div
          class="banner-1 w-2/3 inline-block"
          data-cy="checkout-bankslip_info_email-text"
        >
          {{ $t("checkout.changePayment.paymentSlip.infoNextPaymentSlip") }}
          {{ nextBillingDate }}
        </div>
      </div>

      <div class="w-full text-center mt-10">
        <submit-button-default
          :text="$t('understood')"
          :fatButton="true"
          @click.native="agreeAction(); eventGtm('custom.changePayment-BlankSlip-Success')"
          data-cy="checkout-success_bankslip-btn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs-ext";
import CheckoutHeader from "@/components/Checkout/Header.vue";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import { CHANGE_PAYMENT_METHOD_VIEW, HOME_VIEW } from "@/router/constants";
import { mapActions, mapGetters } from "vuex";
import {
  CHANGE_PAYMENT_METHOD_BANKSLIP,
  STORE_MODULE,
  STORE
} from "@/store/constants/store";
import { CHECKOUT_MODULE } from "@/store/constants/checkout";
export default {
  components: {
    CheckoutHeader,
    SubmitButtonDefault,
  },
  data() {
    return {
      isConfirmed: false,
      newPaymentInfo: {},
    };
  },
  computed: {
    ...mapGetters(STORE_MODULE, ["storeProfile", "subscription"]),
    ...mapGetters({ isDesktop: `isDesktop`, eventGtm: 'eventGtm', getProfile: `${STORE_MODULE}/${STORE}`}),
    getEmail() {
      return this.storeProfile.account;
    },
    nextBillingDate() {
      let nextBillingDate = dayjs(
        this.subscription.subscription_expiration ??
          this.subscription.license_expiration
      ).subtract(3, "day");
      if (dayjs().isAfter(nextBillingDate)) {
        nextBillingDate = dayjs().add(1, "day");
      }

      return nextBillingDate.format("DD/MM/YY");
    },
  },

  methods: {
    ...mapActions({
      changePaymentMethod: `${CHECKOUT_MODULE}/${CHANGE_PAYMENT_METHOD_BANKSLIP}`,
    }),
    backBtnAction() {
      return this.$router.push({
        name: CHANGE_PAYMENT_METHOD_VIEW,
      });
    },
    agreeAction() {
      if (this.isDesktop) {
        return alert("@@finalizarfluxoassinatura");
      }
      return this.$router.push({
        name: HOME_VIEW,
      });
    },
    async confirmChange() {
      const data = { 
        newMethod: this.$route?.params?.method, 
        priceId: this.getProfile.store.priceId ?? this.getProfile.planServiceId, 
        gateway: "Ebanx"
      };
      this.newPaymentInfo = await this.changePaymentMethod(data);
      this.isConfirmed = true;
    },
  },
};
</script>

<style>
</style>